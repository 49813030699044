<template>
  <ion-page class="page">
    <p>Last Modified: February 9, 2022</p>
    <h1>Terms of Service</h1>
    <div class="margin-bottom-10">
      UsePencil, Inc., a Delaware corporation (“Company,” “we,” “us,” or “our”), owns and operates CharacterHub.com and
      other sites linking to these Terms of Service, including the mobile versions of those sites (individually, the
      “Site,” or, collectively, the “Sites” or the “App”). As further described below the Company allows users (each, a
      “User”) to access, create, share and comment on "characters", "worlds", “stories” comprised of images and textual
      content (“Story,”
      “Stories”) and perform other activities as are enabled by the functionality of the services (such service
      collectively with the Site, the App and any other related services the Company offers from time to time,
      hereinafter known as the “Service”).
    </div>

    <div class="margin-bottom-10">TERMS AND CONDITIONS</div>
    <div class="margin-bottom-10">
      These Terms of Service are an agreement between you and the Company (“Agreement”) governing your access to and the
      use of the Sites, their functionality, and their content. If you are viewing this on your mobile device, you can
      also view this Agreement via a web browser at characterhub.com/terms.
    </div>
    <div class="margin-bottom-10 bold">
      BEFORE USING THE SERVICE, PLEASE READ THIS AGREEMENT CAREFULLY. BY CLICKING “JOIN THE COMMUNITY” OR ANOTHER
      MECHANISM (“ACCEPTANCE MECHANISM”) THAT INDICATES YOU ARE ACCEPTING THIS AGREEMENT OR OTHERWISE USING THE SERVICE,
      YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT, INCLUDING, THE PROVISIONS
      OF SECTION 18 UNDER WHICH YOU AGREE TO ARBITRATE CERTAIN CLAIMS INSTEAD OF GOING TO COURT AND AGREE NOT TO BRING
      OR PARTICIPATE IN CLASS ACTIONS CLAIMS. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT,
      THEN PLEASE DO NOT CLICK THE ACCEPTANCE MECHANISM (IN WHICH CASE YOU WILL NOT BE ABLE TO USE THE SERVICE’S
      FUNCTIONALITY SUCH MECHANISM ENABLES) OR OTHERWISE USE THE SERVICE.
    </div>
    <div class="margin-bottom-10">
      <b>Note regarding Children.</b> If you live in a country in the European Region, you must be at least 16 years old
      to use our Services or such greater age required in your country to register for or use our Services. If you live
      in any other country except those in the European Region, you must be at least 13 years old to use our Services or
      such greater age required in your country to register for or use our Services. In addition to being of the minimum
      required age to use our Services under applicable law, if you are not old enough to have the authority to agree to
      our Terms in your country, your parent or guardian must agree to our Terms on your behalf. The Services are not
      for persons under the age of 13. If you are under 13 years of age, then please do not use the Services. By
      clicking the "I Agree" button or by otherwise using or registering an account for the Services, you represent that
      you meet the above age requirements. Additionally, please be aware that Company has created certain areas on the
      Service that contain adult or mature content. You must be at least 18 years of age to access and view such areas.
    </div>
    <div class="margin-bottom-10">
      We may revise and update these Terms of Service from time to time. All changes are effective immediately when
      posted. You are expected to check these Terms of Service from time to time, so you are aware of any changes, as
      they are binding on you. Your continued use of the Sites following such posting means that you accept and agree to
      the changes.
    </div>
    <div class="margin-bottom-10">
      Our Privacy Policy, at characterhub.com/privacy, our DMCA Policy, at characterhub.com/dmca, and any additional
      terms and conditions, guidelines and rules that we may post from time to time, all we may update from time to
      time, are hereby incorporated into this Agreement, and you hereby agree to policies, rules and guidelines therein.
    </div>
    <div class="margin-bottom-10">
      <strong>1. How it Works.</strong> The Service allows Users to upload and input certain Media (as defined below)
      into the Service to create a Character, Story, World, and Art, and to view, comment and share upon their own and
      other Users’ works. Users
      may post and share Characters in such formats are the Service supports from time to time, including, without
      limitation, as video content which may be shared both on the Service and via other online channels, including,
      without limitation, using email and Third Party Services (as defined below).
    </div>
    <div class="margin-bottom-10">
      <strong>2. License to Use the App.</strong> If you have downloaded the App, then, subject to your compliance with
      all the terms and conditions of this Agreement, the Company grants you a limited, nonexclusive, nontransferable,
      revocable license to install and use the App on a compatible mobile device that you own or control for your
      personal, non-commercial purposes, in each case in the manner enabled by the Company. If you are using the App on
      an Apple, Inc. (“Apple”) iOS device or if you downloaded the App on an Android device via the Google Play Store
      from Google, Inc. (“Google”), the preceding license is further limited to use permitted by the Usage Rules set
      forth in Apple’s App Store Terms of Service, or the applicable Google terms and conditions, respectively
      (collectively, the “App Store Terms”). Any use of the App other than for use in compliance with applicable App
      Store Terms, is strictly prohibited.
    </div>
    <div class="margin-bottom-10">
      <strong>3. Media.</strong>
    </div>
    <div class="margin-bottom-10">
      <u>3.1 Media.</u> The Service allows you and other Users to input and upload text, images, and other digital
      content to create Stories and to share your Stories with other Users (“Media”). We may remove some or all of your
      Media at any time if we feel it is necessary to do so. By way of example, your Media may be removed if we
      determine that its content is inappropriate or if it is the subject of flagging by other Users.
    </div>
    <div class="margin-bottom-10">
      <u>3.2 Your License to Company.</u> <span class="bold">You shall retain all of your ownership rights in your
        Media</span>, but we need to
      license certain rights from you in order to make your Media available on the Service. You hereby grant the Company
      a non-exclusive, sublicensable and transferable license to reproduce, publish, distribute, perform, display, and
      transmit your Media for purposes of providing the Service and for troubleshooting and improving the Service. This
      license expressly includes the right for us and other Users to embed your Stories and other Media on Third-Party
      Services and you agree that those Stories and other Media may remain available on such Third-Party Services after
      termination of this Agreement. Media you submit to Third-Party Services (as defined in Section 7) through the
      Service or to the Service via such Third Party Services is subject to the terms and conditions of the applicable
      Third-Party Service. You agree that we may use your Media to promote the Service and that we may sell ads on the
      Service without further obligation to you.
    </div>
    <div class="margin-bottom-10">
      3.3 You agree you are responsible for all of your Media and all activities that occur under your User account.
      Accordingly, you agree that you will not: (i) use material that is subject to third party intellectual property or
      proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have
      permission from their rightful owner to post the material and to grant the Company all of the license rights
      granted herein; or (ii) use material that is unlawful, defamatory, libelous, threatening, pornographic (including,
      without limitation, child porn or nonconsensual ("revenge") porn), obscene, harassing, hateful, racially or
      ethnically offensive or encourages conduct that would be considered a criminal offense, violate any law or is
      otherwise inappropriate. You agree, to the extent, the Service provides such functionality, to accurately tag and
      identify the nature of your uploaded Media, including, without limitation, by selecting appropriate tags and
      identifiers with respect to adult or mature Media. The Company may investigate an allegation that any Media does
      not conform to this Agreement and may determine in good faith and its sole discretion whether to remove such
      Media, which it reserves the right to do at any time. If you are a copyright holder and believe in good faith that
      your content has been made available through the Service without your authorization, you may follow the process
      outlined in the Company’s DMCA Policy to request that the Company remove such content.
    </div>
    <div class="margin-bottom-10">
      3.4 You hereby acknowledge that you may be exposed to Media from other Users that is inaccurate, offensive,
      obscene, indecent, or objectionable when using the Service, and further acknowledge that the Company does not have
      any obligation to control the Media shared by other Users and does not have any obligation to monitor such content
      for any purpose.
    </div>
    <div class="margin-bottom-10">
      <strong>4. Representations and Warranties.</strong> You represent, warrant and covenant that, in connection with
      this Agreement or the Service, you will not and will not attempt to: (i) violate any laws, third party rights or
      our other policies; (ii) offer for sale or rent any products or services; (iii) re-join or attempt to use the
      Service if the Company has banned or suspended you; (iv) defraud the Company or another user; or (vi) use another
      User’s account or allow another person to use your User account.
    </div>
    <div class="margin-bottom-10">
      <b>5. Ownership; Proprietary Rights.</b> You own all the rights to the content you create and post on the Sites
      and through use of the Service, subject only the rights granted to us in Section 3.2. The Company owns all
      worldwide right, title and interest, including all intellectual property and other proprietary rights, in and to
      the Service, all content available in connection therewith (other than your Media) and all usage and other data
      generated or collected in connection with the use thereof (the “Company Materials”). Except for as expressly set
      forth herein, you agree not to make any unauthorized use of the Company Materials. You agree not to reverse
      engineer, decompile, disassemble or otherwise attempt to discover the source code, algorithm, or programs
      underlying the Company Materials. The Company reserves the right to modify or discontinue the Service or any
      version(s) thereof at any time in its sole discretion, with or without notice.
    </div>
    <div class="margin-bottom-10">
      <b>6. Third Party Sites.</b> The Service may include advertisements or other links that allow you to access web
      sites or other online services that are owned and operated by third parties. You acknowledge and agree that the
      Company is not responsible or liable for such third party sites, or products or services made available through
      them, or your use of them, except as outlined in our Privacy policy .
    </div>
    <div class="margin-bottom-10">
      <b>7. Third-Party Services.</b> The Service may include features or functionality that interoperate with online
      services operated by third parties (such services, “Third-Party Services”), pursuant to agreements between Company
      and the operators of such Third-Party Services (such agreements, “Third-Party Agreements” and such operators,
      “Operators”) or through application programming interfaces or other means of interoperability made generally
      available by the Operators (“Third-Party APIs”) which Company does not control. Third-Party Agreements and
      Third-Party APIs (and the policies, terms, and rules applicable to Third-Party APIs) may be modified, suspended or
      terminated at any time. We shall have no liability for any such modification, suspension or termination. You are
      responsible for ensuring that your use of the Service in connection with any Third-Party Service complies with all
      agreements, policies, terms and rules applicable to such Third-Party Service.
    </div>
    <div class="margin-bottom-10">
      <b>8. Mobile Services; SMS.</b> Use of the Service requires the usage of data and messaging services provided by
      your wireless service carrier. In particular, we may use SMS services to provide you with notifications related to
      your use of the Service. You hereby consent to receive such messages. You acknowledge and agree that you are
      solely responsible for data usage fees, messaging fees and any other fees that your wireless service carrier may
      charge in connection with your use of the Service.
    </div>
    <div class="margin-bottom-10">
      <b>9. Fees and Payments.</b>
    </div>
    <div class="margin-bottom-10">
      9.1 Payment processing for the Services is provided by such third party payment processor as we may utilize from
      time to time (“Payment Processor”). We do not collect or store your credit card information. You can find out more
      about both our privacy practices and our Payment Processor’s privacy practice in our Privacy Policy and the links
      therein. By providing a credit card or other payment method accepted by us, you represent and warrant that you are
      authorized to use the designated payment method and that you authorize our Payment Processor to charge your
      payment method for the recurring and/or nonrecurring fees as displayed to you at the time you create your account
      and as may be modified from time to time as described in this Agreement, as well as any other fees you expressly
      choose to incur in connection with your use of the Service (including any applicable taxes and other charges). If
      the payment method you provide cannot be verified, is invalid, or is otherwise not acceptable, your account may be
      suspended or canceled. You must resolve any problem we or our Payment Processor encounters to proceed with your
      use of your account.
    </div>
    <div class="margin-bottom-10">
      Unless otherwise specified upon enrollment, for subscription products or services, your payment method will be
      authorized for up to a month as soon as the free trial, if any, has concluded for the applicable Service account
      type and monthly after that until you cancel the subscription. You acknowledge and agree that the payment
      instrument provided by you will be automatically charged the fees you incur in connection with your use of the
      Service, and represent and warrant that you have all necessary rights relating to such payment instrument to
      authorize Company to make such charges. Your use of the Service may be suspended if we are unable to charge such
      payment instrument for any reason or if your account is otherwise past due. The fees applicable to your account
      may be subject to modification from time to time pursuant to notice (which may be given via e-mail) provided by us
      at least thirty (30) days in advance of the payment date for which the modification would be effective. You may at
      any time, cancel your account as set forth below if you do not agree to any modified fees. All fees must be paid
      in U.S. dollars (or such other currency(ies) which may be accepted by Company from time to time, as indicated at
      the time of payment) and are non-refundable.
    </div>
    <div class="margin-bottom-10 red">
      FOR ANY PAID SUBSCRIPTION SERVICE ACCOUNT TYPE, YOU WILL BE AUTOMATICALLY ENROLLED IN (AND CHARGED FOR) THE
      SUBSCRIPTION PLAN YOU SELECTED FOLLOWING THE END OF ANY APPLICABLE FREE TRIAL PERIOD. YOU MUST CANCEL before THE
      END OF YOUR FREE TRIAL IN THE MANNER SPECIFIED BELOW TO AVOID BEING CHARGED.
    </div>
    <div class="margin-bottom-10 red">
      FOR SUBSCRIPTIONS DIRECTLY ON CHARACTERHUB.COM, YOU MAY CANCEL YOUR SUBSCRIPTION AT ANY TIME BY CANCELING IN YOUR
      SETTINGS. IF YOU CANCEL YOUR SUBSCRIPTION, YOU MAY STILL USE YOUR SUBSCRIPTION UNTIL THE END OF YOUR
      THEN-CURRENT SUBSCRIPTION MONTH. TO NOT BE CHARGED FOR YOUR SUBSCRIPTION FOR THE FOLLOWING SUBSCRIPTION MONTH, YOU
      MUST CANCEL YOUR SUBSCRIPTION AT LEAST THIRTY (30) DAYS before THAT MONTH, OR YOU WILL OTHERWISE BE CHARGED FOR
      THAT MONTH’S SUBSCRIPTION. ALL CANCELLATION REQUESTS RECEIVED LESS THAN THIRTY (30) DAYS BEFORE THE FOLLOWING
      SUBSCRIPTION MONTH WILL APPLY TO THE FOLLOWING CYCLE.
    </div>
    <div class="margin-bottom-10 red">
      FOR SUBSCRIPTIONS ON A MOBILE APP, YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW UNLESS AUTO-RENEW IS TURNED
      OFF AT LEAST 24 HOURS BEFORE THE END OF THE CURRENT PERIOD. YOU CAN GO TO YOUR APPLE APP STORE OR GOOGLE PLAY
      ACCOUNT SETTINGS TO MANAGE
      YOUR SUBSCRIPTION AND TURN OFF AUTO-RENEW. YOUR ITUNES ACCOUNT WILL BE CHARGED WHEN THE PURCHASE IS CONFIRMED. TO
      THE EXTENT YOU ARE SIGNING UP TO TEST OUR SERVICE AS PART OF A FREE TRIAL, YOU MAY CANCEL YOUR SUBSCRIPTION before
      THE END OF THE FREE TRIAL IN ACCORDANCE WITH THE CANCELLATION AS MENTIONED ABOVE POLICIES. IF YOU SUBSCRIBE BEFORE
      YOUR FREE TRIAL ENDS, THE REST OF YOUR FREE TRIAL WILL BE FORFEITED AS SOON AS YOUR PURCHASE IS CONFIRMED.
    </div>
    <div class="margin-bottom-10">
      <b>9.2 Patreon Program.</b> From time to time, we may offer functionality that allows you to contribute funding
      toward another User’s subscription. You agree that once you have selected the applicable User to whom you would
      like to contribute (the “Donee”) and approved your donation, your payment will be processed by our Payment
      Processor and the donated funds will not be refundable or recoupable, and you will have no claim, right or title
      to the donated funds. We do not make any representations about your ability to make any tax deduction or any other
      benefit or claim to the donated funds, including, without limitation, with respect to any cancellations or
      terminations of the applicable User’s account. You agree that you will not receive any benefit from Company or the
      Donee, including, without limitation, with respect to the applicable User’s Media or the intellectual property
      rights therein.
    </div>
    <div class="margin-bottom-10">
      <b>10. Micropayments.</b> The Services do not require you to make any purchases. However, they may offer
      opportunities to buy or earn virtual items, including virtual coins, tokens, points, or other goods and services
      (collectively, “Virtual Items”). If you choose to purchase Virtual Items, you will be charged a fee in “real
      world” currency for a limited, personal, non-transferable, and revocable license to use these Virtual Items
      exclusively within the Services, solely for personal, non-commercial, entertainment purposes. Virtual Items have
      no equivalent real-world monetary value and cannot be redeemed or exchanged for cash, goods, or any other item of
      monetary value with us or any other party. Virtual Items are provided solely for additional entertainment within
      the Services.<br>
      You understand that Virtual Item pricing may be dynamic and vary over time and for different users. Additionally,
      while you may “earn,” “buy,” or “purchase” Virtual Items within our Services, you do not have any ownership
      interest in them, nor do the amounts of any Virtual Items reflect real currency or any real-world credit balance.
      We retain ownership of your account and any associated Virtual Items. The quantities of Virtual Items or any
      “virtual currency” balances displayed in your account have no value outside of the Services and only represent the
      extent of your limited license. We reserve the right to modify or eliminate Virtual Items (or their pricing) at
      any time at our sole discretion, with or without notice.<br>
      Virtual Items purchased within our site or app on other platforms, such as Apple or Google, are subject to those
      platforms’ payment terms and conditions. We do not control payment processes on third-party platforms and are not
      responsible for processing such payments. Refer to those platforms’ terms and conditions for further details. Your
      order for Virtual Items is an offer to obtain a limited license to those Virtual Items, which we accept when the
      Virtual Items are made available in your account or payment is processed, whichever occurs first. Your limited
      license to use Virtual Items begins upon our acceptance of your payment. Virtual Items will remain available in
      your account until used within the Services or surrendered due to termination of the Services as outlined in these
      Terms.<br>
      You may not transfer Virtual Items outside of the Services (e.g., in the “real world”) by selling, gifting, or
      trading them. Such transfers are not recognized by us as legitimate. Additionally, you are prohibited from
      sublicensing, trading, selling, or attempting to exchange Virtual Items for real currency or value outside of the
      Services. Any prohibited or attempted transfer will be void, and we may terminate your account in response, among
      other remedies available to us under these Terms or applicable law.
    </div>
    <div class="margin-bottom-10">
      <b>11. Sales & Payment Terms.</b> By purchasing Virtual Items or other content as may be offered, you agree to pay
      us (directly or via third-party platforms) the applicable charges for your purchase, including any taxes incurred
      by you or anyone using an account registered to you, using a valid payment method that we (or the relevant
      third-party platform) accept, in accordance with billing terms and prices in effect when the charge is made.
      <br>All Virtual Item sales are final. If you are charged for Virtual Items that you did not purchase, did not
      receive, or for which you were charged incorrectly, you may request a refund or correction following our or the
      relevant third-party payment provider’s policies. Purchases made on platforms such as Apple or Google are subject
      to those platforms’ refund policies, and requests for refunds must be sent directly to those providers. For
      purchases made directly from us, you may request a refund by contacting Customer Support or following instructions
      provided within our Services. Refund requests to us must be submitted within 96 hours after the purchase, and
      requests to third-party payment providers must be submitted within their specified time periods.
      <br><span>ALL SALES ARE FINAL. WE ARE UNDER NO OBLIGATION TO ISSUE REFUNDS, AND YOU WILL NOT RECEIVE MONEY OR OTHER
      COMPENSATION FOR UNUSED VIRTUAL ITEMS IF YOUR ACCOUNT IS CLOSED, WHETHER VOLUNTARILY OR INVOLUNTARILY, OR IF YOU
      PURCHASED THROUGH OUR SITE, SERVICES, OR A THIRD-PARTY PLATFORM SUCH AS APPLE, GOOGLE, OR OTHERS. ALL UNUSED
      VIRTUAL ITEMS WILL BE FORFEITED IF WE TERMINATE OR SUSPEND YOUR ACCOUNT FOR ANY REASON, AT OUR DISCRETION, OR IF
      THE SERVICES (OR ANY ASPECT OF THEM) ARE DISCONTINUED. IF YOUR ACCOUNT OR ANY SUBSCRIPTION TO THE SERVICES
      ASSOCIATED WITH YOUR ACCOUNT IS TERMINATED, SUSPENDED, OR WE REVOKE VIRTUAL ITEMS FROM YOUR ACCOUNT, NO REFUND
      WILL BE PROVIDED, NOR WILL VIRTUAL ITEMS BE CREDITED, CONVERTED TO CASH, OR OTHERWISE COMPENSATED. PURCHASES FOR
      LIMITED LICENSES TO VIRTUAL ITEMS ARE NON-REFUNDABLE TO THE EXTENT PERMITTED BY LAW.</span>
      <br>By making any purchase of Virtual Items, you acknowledge that the sale is final and that we have no obligation
      to issue a refund after a transaction is completed. Upon purchasing a Virtual Item, you request immediate delivery
      upon acceptance of your order. If you reside in the European Economic Area or the United Kingdom, this means that
      you waive your statutory right to withdraw from the purchase once you access and use the Virtual Item.
      <br>If a refund is issued for a Virtual Item purchase, we may revoke your access to that Virtual Item. We may (i)
      deny any refund request, (ii) suspend or cancel any refund payment previously approved, and (iii) revoke your
      access to a Virtual Item related to that refund if we identify fraud, abuse, or any other manipulative behavior.
    </div>
    <div class="margin-bottom-10">
      <b>12. Contests.</b> From time to time, we may sponsor contests that are featured on one of our Sites. You agree
      that your entrance into any of our contests subjects you to the rules and guidelines that we may occasionally
      publish for participation in such contests. Additionally, you agree that by participating in any contest, you
      consent to have your submission shared on our Sites. Occasionally either the Company on its own or in conjunction
      with any of its partners may provide prizes to the winners of the contests. The prize(s) that may be awarded to
      the eligible winner(s) are not transferable, redeemable for cash or exchangeable for any other prize. By
      participating in the contest, each participant and winner waives all claims of liability against the Company, its
      employees and agents, the contest’s sponsors and their respective employees and agents, for any personal injury or
      loss which may occur from the conduct of, or participation in, the contest, or from the use of any prize.
    </div>
    <div class="margin-bottom-10">
      <b>13. Prohibited Uses.</b> As a condition of your use of the Service, you will not use the Service for any
      purpose that is unlawful or prohibited by this Agreement. You may not use the Service in any manner that in our
      sole discretion could damage, disable, overburden, impair, or interfere with any other party’s use of it. You may
      not obtain or attempt to obtain any materials or information through any means not intentionally made available
      through the Service. You agree not to scrape or otherwise use automated means to access or gather information from
      the Service and agree not to bypass any robot exclusion measures we may put into place. In addition, you agree not
      to use false or misleading information in connection with your User account, and acknowledge that we reserve the
      right to disable any User account with a profile which we believe (in our sole discretion) is false or misleading
      (including a profile that impersonates a third party).
      <br>Unless expressly permitted or otherwise approved in writing by us, you will not, and will not attempt to, or
      permit or enable others to: (a) access or use any of our Services and Data on behalf of a business or as part of a
      monetized service or product; or (b) sell, lease, sublicense, monetize, or otherwise derive revenue from any
      portion of our Services and Data, whether directly or indirectly, including any data derived from them.
      <br>Additionally, no rights or licenses are granted or implied beyond what is specified in these Terms, including
      any right to use site content—such as Stories or Media—for purposes beyond those stated herein, such as for
      training machine learning or artificial intelligence models, without the express written permission of the
      rightsholders.
    </div>
    <div class="margin-bottom-10">
      <b>14. Termination.</b> You agree that the Company, in its sole discretion and for any or no reason, may terminate
      this Agreement, your account or your use of the Service, at any time and without notice. You agree that the
      Company shall not be liable to you or any third-party for any such termination. Sections 3 (solely with respect to
      your grants of rights therein) and 4 through 19 will survive any termination of this Agreement.
    </div>
    <div class="margin-bottom-10">
      <b>15. Disclaimers; No Warranties.</b> THE SERVICE AND ANY MEDIA, INFORMATION OR OTHER MATERIALS MADE AVAILABLE IN
      CONJUNCTION WITH OR THROUGH THE SERVICE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
      IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE COMPANY AND ITS LICENSORS, SERVICE
      PROVIDERS AND PARTNERS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. THE
      COMPANY AND ITS LICENSORS, SERVICE PROVIDERS AND PARTNERS DO NOT WARRANT THAT THE FEATURES AND FUNCTIONALITY OF
      THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE OR THE
      SERVERS THAT MAKE AVAILABLE THE FEATURES AND FUNCTIONALITY THEREOF ARE FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR
      ALL OF THE FOREGOING DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
      RIGHTS.
    </div>
    <div class="margin-bottom-10">
      <b>16. Indemnification.</b> You agree to indemnify and hold the Company and its affiliated companies, and each of
      their officers, directors, and employees, harmless from any claims, losses, damages, liabilities, costs and
      expenses, including reasonable attorney’s fees, (any of the preceding, a “Claim”) arising out of or relating to
      your use or misuse of the Service, breach of this Agreement or infringement, misappropriation or violation of the
      intellectual property or other rights of any other person or entity, provided that the preceding does not obligate
      you to the extent the Claim arises out of the Company’s willful misconduct or gross negligence. The Company
      reserves the right, at our own expense, to assume the exclusive defense and control of any matter for which you
      are required to indemnify us and you agree to cooperate with our defense of these claims.
    </div>
    <div class="margin-bottom-10">
      <b>17. Limitation of Liability and Damages.</b> UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
      SHALL THE COMPANY OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, OR THIRD PARTY PARTNERS,
      LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
      EXEMPLARY DAMAGES THAT ARISE OUT OF OR RELATE TO THE SERVICE, INCLUDING YOUR USE THEREOF, OR ANY OTHER
      INTERACTIONS WITH THE COMPANY, EVEN IF THE COMPANY OR A COMPANY AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL
      OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU, IN WHICH CASE THE COMPANY’S
      LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW. IN NO EVENT SHALL THE TOTAL LIABILITY OF COMPANY OR ITS
      AFFILIATES, CONTRACTORS, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, OR THIRD PARTY PARTNERS, LICENSORS OR SERVICE
      PROVIDERS TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT OR
      YOUR USE OF THE SERVICE EXCEED ONE HUNDRED U.S. DOLLARS.
    </div>
    <div class="margin-bottom-10">
      <b>18. Arbitration.</b>
    </div>
    <div class="margin-bottom-10">
      <u>18.1 Agreement to Arbitrate.</u> This Section 18 is referred to herein as the “Arbitration Agreement.” The
      parties agree that any and all controversies, claims, or disputes between you and Company arising out of, relating
      to, or resulting from this Agreement, shall be subject to binding arbitration pursuant to the terms and conditions
      of this Arbitration Agreement, and not any court action (other than a small claims court action to the extent the
      claim qualifies and other than claims for injunctive or other equitable relief). The Federal Arbitration Act
      governs the interpretation and enforcement of this Arbitration Agreement.
    </div>
    <div class="margin-bottom-10">
      <u>18.2 Class Action Waiver.</u> THE PARTIES AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
      INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
      PROCEEDING. UNLESS BOTH PARTIES AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S
      OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
      PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN
      FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY
      THAT PARTY’S INDIVIDUAL CLAIM(S).
    </div>
    <div class="margin-bottom-10">
      <u>18.3 Procedures.</u> Arbitration will be conducted by a neutral arbitrator in accordance with the American
      Arbitration Association’s (“AAA”) rules and procedures (the “AAA Rules”), as modified by this Arbitration
      Agreement. If there is any inconsistency between the AAA Rules and this Arbitration Agreement, the terms of this
      Arbitration Agreement will control unless the arbitrator determines that the application of the inconsistent
      Arbitration Agreement terms would not result in a fundamentally fair arbitration. The arbitrator must also follow
      the provisions of this Agreement as a court would, including without limitation, the limitation of liability
      provisions in Section 17. You may visit http://www.adr.org for information on the AAA and
      http://www.adr.org/fileacase for information on how to file a claim against the Company.
    </div>
    <div class="margin-bottom-10">
      <u>18.4 Venue.</u> The arbitration shall be held in the county in which you reside or at another mutually agreed
      location. If the value of the relief sought is $10,000 or less, you or Company may elect to have the arbitration
      conducted by telephone or based solely on written submissions, which election shall be binding on each party, but
      subject to the arbitrator’s discretion to require an in-person hearing if the circumstances warrant. Attendance at
      any in-person hearing may be made by telephone by either or both parties unless the arbitrator requires otherwise.
    </div>
    <div class="margin-bottom-10">
      <u>18.5 Governing Law.</u> The arbitrator will decide the substance of all claims in accordance with the laws of
      the state of California, without regard to its conflicts of laws rules, and will honor all claims of privilege
      recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different Service
      users, but is bound by rulings in prior arbitrations involving you to the extent required by applicable law.
    </div>
    <div class="margin-bottom-10">
      <u>18.6 Costs of Arbitration.</u> Payment of all filing, administration, and arbitrator fees (collectively, the
      “Arbitration Fees”) will be governed by the AAA’s Rules. Each party will be responsible for all other fees it
      incurs in connection with the arbitration, including without limitation, all attorney fees.
    </div>
    <div class="margin-bottom-10">
      <u>18.7 Confidentiality.</u> All aspects of the arbitration proceeding, and any ruling, decision or award by the
      arbitrator, will be strictly confidential for the benefit of all parties.
    </div>
    <div class="margin-bottom-10">
      <u>18.8 Severability.</u> If a court decides that any term or provision of this Arbitration Agreement other than
      Section 18.2 is invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent
      necessary, and this Arbitration Agreement shall be enforceable as so modified. If a court decides that any of the
      provisions of Section 18.2 is invalid or unenforceable, then the entirety of this Arbitration Agreement shall be
      null and void. The remainder of this Agreement will continue to apply.
    </div>
    <div class="margin-bottom-10">
      <b>19 Miscellaneous.</b> Under this Agreement, you consent to receive communications from the Company
      electronically. This Agreement shall be governed by and construed in accordance with the laws of the State of
      California, without giving effect to any principles of conflicts of law. You agree that any action at law or in
      equity arising out of or relating to this Agreement or the Service that is not subject to arbitration under
      Section 18 shall be filed only in the state or federal courts in California and you hereby consent and submit to
      the personal jurisdiction of such courts for the purposes of litigating any such action. Notwithstanding the
      preceding, either party may petition any court of competent jurisdiction for injunctive or other equitable relief.
      The failure of any party at any time to require performance of any provision of this Agreement shall in no manner
      affect such party’s right at a later time to enforce the same. A waiver of any breach of any provision of this
      Agreement shall not be construed as a continuing waiver of other breaches of the same or other provisions of this
      Agreement. This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by
      you but may be assigned by the Company without restriction. This is the entire agreement between us relating to
      the subject matter herein and shall not be modified except in writing, signed by both parties, or by a change to
      this Agreement made by the Company as set forth herein.
    </div>
    <div class="margin-bottom-10">
      <b>20. More Information; Contact.</b> The services hereunder are offered by UsePencil, Inc., email:
      admin@characterhub.com, address: 1259 El Camino Real #158 Menlo Park, CA 94025. If you are a California resident,
      we are required to inform you that you may reach the Complaint Assistance Unit of the Division of Consumer
      Services of the California Department of Consumer Affairs via mail at 1625s North Market Blvd., Suite N112,
      Sacramento, CA 95834 or telephone at (916) 445-1254 or (800) 952-5210. Hearing-impaired users can reach the
      Complaint Assistance Unit at TDD (800) 326-2297 or TDD (916) 322-1700.
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
</script>

<style lang="sass" scoped>
.red
  color: red
.bold
  font-weight: bold
.margin-bottom-10
  margin-bottom: 10px
</style>